<template>
  <div class="d-flex flex-column chat-card overflow-hidden">
    <BackToChats v-if="chat" :text="theOther.complete_name"></BackToChats>
    <div v-if="chat" style="flex: 1 1 1px" class="overflow-y-auto d-flex flex-column-reverse px-2">
      <div v-for="(msg, i) in chat.messages.slice().reverse()" :key="i" :class="[{ 'flex-row-reverse': msg.user.isMe }, 'd-flex my-2']">
        <message :message="msg" />
      </div>
    </div>

    <div v-if="chat && $apollo.queries.chat.loading" class="d-flex justify-center align-center py-2">
      <v-progress-circular size="23" indeterminate color="primary" />
    </div>

    <v-row v-if="!chat" class="justify-center align-center">
      <v-progress-circular size="46" indeterminate color="primary" />
    </v-row>

    <chat-footer @sendMessage="sendMessage($event)"></chat-footer>
  </div>
</template>

<script>
import gql from "graphql-tag";
import Message, { MESSAGE_FRAGMENT } from "./Message.vue";
import { addToCacheArray } from "../../apollo/helpers";
import moment from "moment";

import ChatFooter from "./ChatFooter.vue";
import BackToChats from "./BackToChats.vue";

const CHAT_QUERY = gql`
  query Chat($id: ID!) {
    chat(id: $id) {
      id
      unreadMessagesByMeCount
      messages {
        ...message
      }
      users {
        id
        isMe
        complete_name
      }
    }
  }
  ${MESSAGE_FRAGMENT}
`;

export default {
  components: { Message, ChatFooter, BackToChats },
  props: { chat_id: String },
  data() {
    return {};
  },
  computed: {
    me() {
      return this.chat.users[0].isMe ? this.chat.users[0] : this.chat.users[1];
    },
    theOther() {
      return !this.chat.users[0].isMe ? this.chat.users[0] : this.chat.users[1];
    },
  },
  apollo: {
    $subscribe: {
      messageRead: {
        query: gql`
          subscription MessageRead($input: MessageReadInput!) {
            messageRead(input: $input) {
              id
              read_at
            }
          }
        `,
        variables() {
          return { input: { chat_id: this.chat_id } };
        },
      },
    },
    chat: {
      query: CHAT_QUERY,
      variables() {
        return { id: this.chat_id };
      },
      skip() {
        return !this.chat_id;
      },
      result({ loading }) {
        if (!loading) this.markMessagesAsRead();
      },
      fetchPolicy: "cache-and-network",
      subscribeToMore: {
        document: gql`
          subscription NewMessage($input: NewMessageInput!) {
            newMessage(input: $input) {
              ...message
              chat {
                id
                unreadMessagesByMeCount
                latestMessage {
                  ...message
                }
              }
            }
          }
          ${MESSAGE_FRAGMENT}
        `,
        variables() {
          return { input: { chat_id: this.chat_id } };
        },
        // Mutate the previous result
        updateQuery: (previousResult, { subscriptionData: { data } }) => {
          const { newMessage } = data;
          previousResult.chat.messages.push(newMessage);
          previousResult.chat.latestMessage = newMessage.chat.latestMessage;
          return previousResult;
        },
        skip() {
          return !this.chat_id;
        },
      },
    },
  },
  methods: {
    markMessagesAsRead() {
      if (this.chat.unreadMessagesByMeCount == 0) return;

      this.$apollo.mutate({
        mutation: gql`
          mutation MarkMessagesAsRead($chat_id: ID!) {
            markMessagesAsRead(chat_id: $chat_id) {
              id
              unreadMessagesByMeCount
            }
          }
        `,
        variables: { chat_id: this.chat_id },
      });
    },

    sendMessage(text) {
      const sendingMessage = {
        __typename: "Message",
        id: -1,
        text,
        read_at: null,
        created_at: moment().format(),
        user: this.me,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation SendMessage($input: SendMessageInput!) {
              sendMessage(input: $input) {
                ...message
                chat {
                  id
                  latestMessage {
                    ...message
                  }
                }
              }
            }
            ${MESSAGE_FRAGMENT}
          `,
          variables: {
            input: {
              text: sendingMessage.text,
              chat_id: this.chat_id,
            },
          },
          update: (store, { data }) =>
            addToCacheArray(store, {
              query: { query: CHAT_QUERY, variables: { id: this.chat_id } },
              queryField: "chat.messages",
              newElement: data.sendMessage,
            }),
          optimisticResponse: {
            __typename: "Mutation",
            sendMessage: {
              ...sendingMessage,
              chat: {
                ...this.chat,
                latestMessage: sendingMessage,
              },
            },
          },
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style></style>
