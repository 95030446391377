<template>
  <v-list class="py-0 flex-grow-0 flex-shrink-1" subheader>
    <v-list-item>
      <v-list-item-action class="d-block d-md-none">
        <v-btn :to="{ name: 'Chats' }" icon text><v-icon>mdi-arrow-left</v-icon></v-btn>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ text }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider class="my-0" />
  </v-list>
</template>

<script>
export default {
  name: "BackToChat",
  props: ["text"],
};
</script>
