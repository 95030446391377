<template>
  <div class="ml-2 font-italic text-no-wrap w-100 text-right caption">
    <v-hover v-slot="{ hover }">
      <div class="d-flex justify-end">
        <v-icon x-small>{{ icon }}&nbsp;</v-icon>
        <div>{{ moment(datetime).format("HH:mm") }}</div>
        <v-expand-x-transition>
          <div v-show="hover">&nbsp;{{ moment(datetime).format("l") }}</div>
        </v-expand-x-transition>
      </div>
    </v-hover>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  props: { datetime: String, "icon": String },
  data() {
    return {
      moment: moment,
    };
  },
};
</script>

<style>
</style>