<template>
  <v-footer elevation="2" class="flex-grow-0 flex-shrink-1" style="border-top: 1px solid #0000001f">
    <v-container class="ma-0 pa-0">
      <v-row no-gutters>
        <v-col>
          <div class="d-flex flex-row align-center">
            <v-text-field
              ref="message"
              :single-line="false"
              v-model="msg"
              placeholder="Type something"
              @keypress.enter="sendMessage"
              clearable></v-text-field>
            <v-btn icon class="ml-4" :disabled="!msg" @click="sendMessage"><v-icon>mdi-send</v-icon></v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "ChatFooter",
  data() {
    return {
      msg: null,
    };
  },
  mounted() {
    this.focusInput();
  },
  methods: {
    sendMessage() {
      if (this.msg == null) return;
      this.$emit("sendMessage", this.msg);
      this.msg = null;
    },
    focusInput() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.message.$refs.input.focus();
        });
      });
    },
  },
};
</script>
