<template>
  <v-card
    flat
    rounded="lg"
    :class="[{ 'lighten-1': true }]"
    :color="message.user.isMe ? 'primary' : 'secondary'"
    max-width="85%"
    min-width="150px"
    dark
  >
    <v-card-text class="pt-2 pb-1 px-4">
      <span class="body-1 white--text">{{ message.text }}</span>
      <hover-date-time :datetime="message.created_at" />
      <hover-date-time v-if="message.read_at && message.user.isMe" :datetime="message.read_at" icon="mdi-eye-outline" />
    </v-card-text>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import HoverDateTime from "./HoverDateTime.vue";

export const MESSAGE_FRAGMENT = gql`
  fragment message on Message {
    id
    text
    read_at
    created_at
    user {
      id
      isMe
    }
  }
`;

export default {
  components: { HoverDateTime },
  props: {
    message: Object,
  },

  data() {
    return {};
  },
};
</script>
